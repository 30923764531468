import { render, staticRenderFns } from "./StudentAfterSales.vue?vue&type=template&id=60d6f546&scoped=true&"
import script from "./StudentAfterSales.vue?vue&type=script&lang=js&"
export * from "./StudentAfterSales.vue?vue&type=script&lang=js&"
import style0 from "./StudentAfterSales.vue?vue&type=style&index=0&id=60d6f546&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d6f546",
  null
  
)

export default component.exports